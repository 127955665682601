import * as math from 'mathjs';
import { gaussian } from './gaussian';
import { radToDeg, inner, clamp } from '../math';

export interface GestureInfo {
  gestureId: number;
  bendDeg2: number[];
  bendDeg31: number[];
  bend: boolean[];
  bone1: number;
  bone2: number;
}

// type Point2D = [number, number];
type Point3D = [number, number, number];

const gestureMap: {
  [key: string]: number;
} = {
  '11111': 1,
  '01111': 2,
  '00000': 3,
  '00111': 4,
  '01110': 5,
};

const f0 = [0];
const f1 = [1, 5, 9, 13, 17];
const f2 = [2, 6, 10, 14, 18];
const f3 = [3, 7, 11, 15, 19];
const f4 = [4, 8, 12, 16, 20];

const adjBendA = [
  [4, 7, 11, 15, 19],
  [3, 6, 10, 14, 18],
];
const adjBendB = [
  [3, 6, 10, 14, 18],
  [2, 5, 9, 13, 17],
];

const BEND_THRESHOLD_2 = [50, 60, 60, 60, 60];
const BEND_THRESHOLD_31 = [60, 80, 90, 100, 100];

let handArrayNp = math.zeros([6, 63]) as number[][];

export const GauF_Hand = math.reshape(gaussian, [6, 63]);

let bendDeg2 = Array(5).fill(undefined);
let bendDeg31 = Array(5).fill(undefined);
let ifBend = Array(5).fill(undefined);

export function dot(v1: number[], v2: number[]): number {
  let product = 0;
  for (let i = 0; i < v1.length; i++) {
    product += v1[i] * v2[i];
  }
  return product;
}

export const sumMultiply = (m1: any, m2: any) => {
  const m3: Array<Array<number>> = [];
  for (let i = 0; i < m1.length; i++) {
    m3[i] = [];
    for (let j = 0; j < m1[i].length; j++) {
      m3[i][j] = m1[i][j] * m2[i][j];
    }
  }
  if (!m3[0]?.length) throw Error('illegal');
  const res: number[] = [];
  for (let i = 0; i < m3[0].length; i++) {
    res[i] = 0;
    for (let j = 0; j < m3.length; j++) {
      res[i] += m3[j][i];
    }
  }
  return res;
};

export const getAbsAngle = (v1: Point3D, v2: Point3D, degree = true) => {
  const rad = Math.acos(
    dot(v1, v2) / ((math.norm(v1) as number) * (math.norm(v2) as number))
  );
  if (degree) return radToDeg(rad);
  return rad;
};

export const vec2deg = (vecA: Point3D, vecB: Point3D) => {
  const d = vecA[0] * vecB[0] + vecA[1] * vecB[1];
  const det = vecA[0] * vecB[1] - vecA[1] * vecB[0];
  return radToDeg(Math.atan2(det, d));
};

const getVector: (p1: number[], p2: number[]) => number[] = (
  p1: number[],
  p2: number[]
) => p1.map((v, i) => p1[i] - p2[i]);

const vProduct = (vA: number[], vB: number[]) => vA[0] * vB[1] - vB[0] * vA[1];

export const getRange = (x: number, min: number, max: number, range: number) =>
  ((clamp(x, min, max) - min) / (max - min)) * range;

export const intersectedDet = (
  A: number[],
  B: number[],
  C: number[],
  D: number[]
) => {
  const AC = getVector(A, C);
  const AD = getVector(A, D);
  const BC = getVector(B, C);
  const BD = getVector(B, D);

  const AB = getVector(A, B);
  const CD = getVector(C, D);
  const allSame = AB[0] + AB[1] + CD[0] + CD[1];

  return (
    vProduct(AC, AD) * vProduct(BC, BD) <= 0 &&
    vProduct(AC, BC) * vProduct(AD, BD) <= 0 &&
    allSame !== 0
  );
};

// eslint-disable-next-line prefer-const
let handArray: Array<Point3D> = Array(21).fill([0, 0, 0]);

export const detectGesture: (
  landmark: any,
  width: number,
  height: number
) => GestureInfo = (landmark: any, width: number, height: number) => {
  for (let i = 0; i < 21; i++) {
    handArray[i] = [
      landmark[i].x,
      landmark[i].y,
      landmark[i].z,
    ];
  }

  handArrayNp = (handArrayNp.slice(1) as Array<any>).concat([
    math.reshape(handArray, [-1]),
  ]);
  const handArrayList = sumMultiply(handArrayNp, GauF_Hand);
  handArray = math.reshape(handArrayList, [21, 3]) as any;
  handArrayNp.splice(5, 1, handArrayList);

  bendDeg2 = bendDeg2.map((v, index) => {
    const [x1, y1, z1] = handArray[adjBendA[0][index]];
    const [x2, y2, z2] = handArray[adjBendA[1][index]];
    const [x3, y3, z3] = handArray[adjBendB[0][index]];
    const [x4, y4, z4] = handArray[adjBendB[1][index]];
    const a = getAbsAngle(
      [x1 - x2, y1 - y2, z1 - z2],
      [x3 - x4, y3 - y4, z3 - z4]
    );
    return Number(a.toFixed(2));
  });

  bendDeg31 = bendDeg31.map((v, index) => {
    const [x1, y1, z1] = handArray[f4[index]];
    const [x2, y2, z2] = handArray[f3[index]];
    const [x3, y3, z3] = handArray[f2[index]];
    const [x4, y4, z4] = handArray[f1[index]];
    return Number(
      getAbsAngle(
        [x1 - x2, y1 - y2, z1 - z2],
        [x3 - x4, y3 - y4, z3 - z4]
      ).toFixed(2)
    );
  });

  const V_17_5 = math.subtract(
    math.matrix(handArray[17]),
    math.matrix(handArray[5])
  ) as math.Matrix;

  const V_17_5_norm = math.norm(V_17_5) as number;
  const innerForward = inner(V_17_5.toArray() as any, [0, 0, 1]) / V_17_5_norm;
  const innerUp = inner(V_17_5.toArray() as any, [0, 1, 0]) / V_17_5_norm;
  ifBend = ifBend.map(
    (v, index) =>
      bendDeg2[index] > BEND_THRESHOLD_2[index] ||
      bendDeg31[index] > BEND_THRESHOLD_31[index]
  );

  let res = '';
  ifBend.forEach((v) => {
    res += String(Number(v));
  });

  let gestureId = gestureMap[res] || -1;

  if (gestureId === 4) {
    gestureId = intersectedDet(
      handArray[2],
      handArray[4],
      handArray[6],
      handArray[8]
    )
      ? gestureId
      : -1;
  }

  if (gestureId === 3) {
    gestureId = intersectedDet(
      handArray[6],
      handArray[8],
      handArray[10],
      handArray[12]
    )
      ? 6
      : 3;
  }

  if (gestureId === 2) {
    const ifThumbBend =
      innerForward < -0.3 || innerUp < 0.6 || handArray[4][1] > handArray[6][1];
    ifThumbBend && (gestureId = 1);
  }

  let bone1 = getAbsAngle(
    getVector(handArray[3], handArray[2]) as Point3D,
    getVector(handArray[10], handArray[9]) as Point3D
  );

  bone1 = (clamp(bone1, 10, 60) - 10) / 50;
  const bone2 = 1 - bone1;

  // 旋转的结果，关节9和关节0连成的向量和一个固定朝上的向量（[0,-1]）进行比较，得到角度，用在“映射9”
  const rotationZ = vec2deg(
    getVector(handArray[9], handArray[0]) as Point3D,
    [0, -1, 0]
  );

  // 速度的结果，可以用在上下踮起脚、左右转或者扭腰子，，用在“映射7”、“映射8”
  let [velocityX, velocityY] = getVector(handArrayNp[5], handArrayNp[0]);
  const vParam = 1000;
  // 除掉屏幕宽高，保证不受分辨率大小影响速度的结果，然后再乘一个可调的系数
  velocityX *= vParam;
  velocityY *= vParam;

  return {
    gestureId,
    bendDeg2,
    bendDeg31,
    bend: ifBend,
    bone1,
    bone2,
    rotationZ: 100 / (1 + 1.05 ** (-rotationZ)) - 50,
    velocityX: clamp(velocityX - 50, -50, 50),
    velocityY: clamp(velocityY - 50, -50, 50),
  };
};
// 输入的手指角度
const angStart = 10;
const angEnd = 150;
// 映射后的手指角度
const angOutStart = 0;
const angOutEnd = 100;

const lineParser = (
  x1: number,
  x2: number,
  y1: number,
  y2: number
): number[] => {
  const first = (y2 - y1) / (x2 - x1);
  const second = (y1 - x1) * first;
  return [first, second];
};
const [paramA, paramB] = lineParser(angStart, angEnd, angOutStart, angOutEnd);

const linearMap = (a: number, b: number, x: number): number => {
  if (x > -90 && x < angStart) {
    x = angStart;
  } else if ((x <= -90 && x >= -180) || (x > angEnd && x <= 180)) {
    x = angEnd;
  }
  return a * x + b;
};

// # INPUT = 10， OUPUT -> 0
// # INPUT = 150， OUPUT -> 100
// # INPUT = 160， OUPUT -> 100
// # INPUT = 80， OUPUT -> 50
export const bendLinear = (inputNumber: number) =>
  linearMap(paramA, paramB, inputNumber);

const [paramA20_50, paramB20_50] = lineParser(20, 50, angOutStart, angOutEnd);
const [paramA20_90, paramB20_90] = lineParser(20, 90, angOutStart, angOutEnd);
const [paramA35_80, paramB35_80] = lineParser(35, 80, angOutStart, angOutEnd);

const linearMapBend2 = (
  a: number,
  b: number,
  x: number,
  numS: number,
  numE: number,
): number => {
  if (x > 0 && x < numS) {
    x = numS;
  } else if ((x <= 0 && x >= 120) || (x >= numE && x <= 120)) {
    x = numE;
  }
  return a * x + b;
};

export const bendLinear20_50 = (inputNumber: number) =>
  linearMapBend2(paramA20_50, paramB20_50, inputNumber, 20, 50);
export const bendLinear20_90 = (inputNumber: number) =>
  linearMapBend2(paramA20_90, paramB20_90, inputNumber, 20, 90);
export const bendLinear35_80 = (inputNumber: number) =>
  linearMapBend2(paramA35_80, paramB35_80, inputNumber, 35, 80);
