export const frag = `
#ifdef GL_ES
precision mediump float;
#endif

uniform sampler2D uSampler1;
uniform sampler2D uSampler2;
uniform sampler2D uSampler3;
uniform sampler2D uSampler4;
uniform sampler2D uSampler5;
uniform sampler2D uSampler6;
uniform float cur;
uniform vec2 uResolution;


vec3 rgb2hsv(vec3 c)
{
    vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
    vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
    vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

    float d = q.x - min(q.w, q.y);
    float e = 1.0e-10;
    return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
}

void main () {
  vec2 uv = gl_FragCoord.xy / uResolution.xy;
  uv.x = uv.x / 2.0;
  vec2 mask = vec2(uv.x + 0.5, uv.y);

  if (cur == 1.0) {
    vec3 hsv = rgb2hsv(texture2D(uSampler1, mask).rgb);
    vec4 color = vec4(texture2D(uSampler1, uv).rgb, hsv.z);
    gl_FragColor = color;
  }
  if (cur == 2.0) {
    vec3 hsv = rgb2hsv(texture2D(uSampler2, mask).rgb);
    vec4 color = vec4(texture2D(uSampler2, uv).rgb, hsv.z);
    gl_FragColor = color;
  }
  if (cur == 3.0) {
    vec3 hsv = rgb2hsv(texture2D(uSampler3, mask).rgb);
    vec4 color = vec4(texture2D(uSampler3, uv).rgb, hsv.z);
    gl_FragColor = color;
  }
  if (cur == 4.0) {
    vec3 hsv = rgb2hsv(texture2D(uSampler4, mask).rgb);
    vec4 color = vec4(texture2D(uSampler4, uv).rgb, hsv.z);
    gl_FragColor = color;
  }
  if (cur == 5.0) {
    vec3 hsv = rgb2hsv(texture2D(uSampler5, mask).rgb);
    vec4 color = vec4(texture2D(uSampler5, uv).rgb, hsv.z);
    gl_FragColor = color;
  }
  if (cur == 6.0) {
    vec3 hsv = rgb2hsv(texture2D(uSampler6, mask).rgb);
    vec4 color = vec4(texture2D(uSampler6, uv).rgb, hsv.z);
    gl_FragColor = color;
  }
}
`;
