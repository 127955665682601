export const DEG2RAD = Math.PI / 180;
export const RAD2DEG = 180 / Math.PI;

export const degToRad = (degrees: number) => degrees * DEG2RAD;

export const radToDeg = (radians: number) => radians * RAD2DEG;

export const inner = (v1: number[], v2: number[]) => {
  let sum = 0;
  v1.forEach((v, index) => {
    sum += v1[index] * v2[index];
  });

  return sum;
};

/**
 * 将 x 夹在 min 和 max 之间
 */
export const clamp = (x: number, min: number, max: number): number => {
  if (min > max) {
    return clamp(x, max, min);
  }

  if (x < min) return min;
  if (x > max) return max;
  return x;
};
export const calAngle = ([x1, y1]: any, [x2, y2]: any, [x3, y3]: any, [x4, y4]: any) => {
  // 求斜率
  const k1 = (y2 - y1) / (x2 - x1)
  const k2 = (y4 - y3) / (x4 - x3)
  // 模长
  const Lx = Math.sqrt(1 + k1 * k1)
  const Ly = Math.sqrt(1 + k2 * k2)
  // 根据向量之间求其夹角并四舍五入
  const Cobb = Math.acos((1 + k1 * k2) / (Lx * Ly)) * 180 / Math.PI + 0.5

  return Cobb
}
export const copy_vector = (from: any, to: any) => {
  let k = from.length
  let k2 = to.length
  if (k !== k2) {
    console.log("the two vector's length is not equal")
    return
  }
  for (var i = 0; i < k; i++) {
    to[i] = from[i]
  }
}
export const Newton_inter_method = (X: any, Y: any, X0: any) => {
  var m = X.length;
  var n = X0.length;
  var Y0: number[] = new Array(n);
  var cp_Y: number[] = new Array(m);
  for (var i1 = 0; i1 < n; i1++) {//遍历X0
    var j = 0;
    copy_vector(Y, cp_Y);
    var kk = j;
    /*求各级均差*/
    while (kk < m - 1) {
      kk = kk + 1;
      for (var i2 = kk; i2 < m; i2++) {
        cp_Y[i2] = (cp_Y[i2] - cp_Y[kk - 1]) / (X[i2] - X[kk - 1]);
      }
    }
    /*求插值结果*/
    var temp = cp_Y[0];
    for (var i = 1; i <= m - 1; i++) {
      var u = 1;
      var jj = 0;
      while (jj < i) {
        u *= (X0[i1] - X[jj]);
        jj++;
      }
      temp += cp_Y[i] * u;
    }

    Y0[i1] = temp;
  }

  return Y0;
}
export const find_peaks = (time_series: any, prominence: number, distance: number, wlen: number) => {
  const { midpoints_m: peaks, left_edges_m, right_edges_m } = local_maxima_1d(time_series)
  var peaks_keep: number[] = []
  var properties: Record<string, any> = {}
  if (distance) {
    var x_peaks: number[] = new Array(peaks.length)
    for (var i = 0; i < peaks.length; i++) {
      x_peaks[i] = time_series[peaks[i]]
    }
    var keep = select_by_peak_distance(peaks, x_peaks, distance)
    for (var i = 0; i < keep.length; i++) {
      if (keep[i] === 1) {
        peaks_keep.push(peaks[i])
      }
    }

  }
  if (prominence) {
    wlen = arg_wlen_as_expected(wlen)
    const { prominences, left_bases, right_bases } = peak_prominences(time_series, peaks, wlen)
    properties.set('prominences', prominences)
    properties.set('left_bases', left_bases)
    properties.set('right_bases', right_bases)
    var pmin = prominence
    var pmax = null
    keep =_select_by_property(properties['prominence'],pmin,pmax)
    
    for (var i = 0; i < keep.length; i++) {
      if (keep[i] === 1) {
        peaks_keep.push(peaks[i])
      }
    }
  }
  return {peaks_keep,properties}

}
export const _select_by_property = (peak_properties: any, pmin: number, pmax: null) => {
  var keep: number[] = new Array(peak_properties.length)
  for (var i = 0; i < peak_properties.length; i++) {
    keep[i] = 1
  }
  if (pmin) {
    for (var i = 0; i < peak_properties.length; i++) {
      if (pmin > peak_properties[i]) {
        keep[i] = 0
      }
    }
  }
  return keep

}
export const peak_prominences = (x: any, peaks: any, wlen: number) => {
  var prominences: number[] = new Array(peaks.length)
  var left_bases: number[] = new Array(peaks.length)
  var right_bases: number[] = new Array(peaks.length)

  for (var peak_nr = 0; peak_nr < peaks.length; peak_nr++) {
    var peak = peaks[peak_nr]
    var i_min = 0
    var i_max = x.length - 1
    if (peak < i_min || peak > i_max) {
      console.log(`peak ${peak} is not a valid index for `)
    }
    if (2 <= wlen) {
      var i_min = Math.max(peak - Math.floor(wlen / 2), i_min)
      var i_max = Math.min(peak + Math.floor(wlen / 2), i_max)
    }

    var i = left_bases[peak_nr] = peak
    var left_min = x[peak]
    while (i_min <= i && x[i] <= x[peak]) {
      if (x[i] < left_min) {
        left_min = x[i]
        left_bases[peak_nr] = i
      }
      i -= 1
      i = right_bases[peak_nr] = peak
      var right_min = x[peak]
      while (i <= i_max && x[i] <= x[peak]) {
        if (x[i] < right_min) {
          right_min = x[i]
          right_bases[peak_nr] = i
        }
        i += 1
      }
      prominences[peak_nr] = x[peak] - Math.max(left_min, right_min)
    }
  }
  return { prominences, left_bases, right_bases }

}
export const local_maxima_1d = (x: any) => {
  var midpoints: number[] = new Array(Math.floor(x.length / 2))
  var left_edges: number[] = new Array(Math.floor(x.length / 2))
  var right_edges: number[] = new Array(Math.floor(x.length / 2))
  var m = 0
  var i = 1
  var i_max = x.length - 1
  while (i < i_max) {
    if (x[i - 1] < x[i]) {
      var i_ahead = i + 1

      while (i_ahead < i_max && x[i_ahead] == x[i]) {
        i_ahead += 1
      }
      if (x[i_ahead] < x[i]) {
        left_edges[m] = i
        right_edges[m] = i_ahead - 1
        midpoints[m] = Math.floor((left_edges[m] + right_edges[m]) / 2)
        m += 1
        i = i_ahead
      }
      i += 1
    }
  }
  var midpoints_m: number[] = new Array(m)
  var left_edges_m: number[] = new Array(m)
  var right_edges_m: number[] = new Array(m)
  for (var i = 0; i < m; i++) {
    midpoints_m[i] = midpoints[i]
    left_edges_m[i] = left_edges[i]
    right_edges_m[i] = right_edges[i]
  }
  return { midpoints_m, left_edges_m, right_edges_m }
}
export const arg_wlen_as_expected = (value: number) => {
  if (!value) {
    value = -1
  }
  else if (1 < value) {
    value = Math.ceil(value)
  }
  else {
    console.log(`wlen must be larger than 1 got ${value}`)
  }
  return value
}

export const select_by_peak_distance = (peaks: any, priority: any, distance: number) => {
  var peak_size = peaks.length
  var distance_ = Math.ceil(distance)
  var keep: number[] = new Array(peak_size)
  for (var i = 0; i < keep.length; i++) {
    keep[i] = 1
  }
  var priority_to_position = argsort(priority)
  for (var i = peak_size - 1; i > -1; i--) {
    var j = priority_to_position[i]
    if (keep[j] == 0) {
      continue
    }
    var k = j - 1
    while (0 <= k && peaks[j] - peaks[k] < distance_) {
      keep[k] = 0
      k -= 1
    }
    k = j + 1
    while (k < peak_size && peaks[k] - peaks[j] < distance_) {
      keep[k] = 0
      k += 1
    }
  }
  return keep
}
export const argsort = (arr: any) => {
  let indices = [...new Array(arr.length)];

  const dsu = (arr1: any, arr2: any) => arr1
    .map((item: any, index: any) => [arr2[index], item]) // add the args to sort by
    .sort(([arg1]: any, [arg2]: any) => arg2 - arg1) // sort by the args
    .map(([, item]: any) => item); // extract the sorted items

  return dsu(indices, arr);
}
