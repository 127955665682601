export const gaussian = [
  0.01468, 0.01468, 0.01468, 0.000085, 0.000085, 0.000085, 0.000085, 0.000085,
  0.000085, 0.000085, 0.000085, 0.000085, 0.000085, 0.000085, 0.000085, 0.01468,
  0.01468, 0.01468, 0.000085, 0.000085, 0.000085, 0.000085, 0.000085, 0.000085,
  0.000085, 0.000085, 0.000085, 0.01468, 0.01468, 0.01468, 0.000085, 0.000085,
  0.000085, 0.000085, 0.000085, 0.000085, 0.000085, 0.000085, 0.000085, 0.01468,
  0.01468, 0.01468, 0.000085, 0.000085, 0.000085, 0.000085, 0.000085, 0.000085,
  0.000085, 0.000085, 0.000085, 0.01468, 0.01468, 0.01468, 0.000085, 0.000085,
  0.000085, 0.000085, 0.000085, 0.000085, 0.000085, 0.000085, 0.000085,
  0.045218, 0.045218, 0.045218, 0.001929, 0.001929, 0.001929, 0.001929,
  0.001929, 0.001929, 0.001929, 0.001929, 0.001929, 0.001929, 0.001929,
  0.001929, 0.045218, 0.045218, 0.045218, 0.001929, 0.001929, 0.001929,
  0.001929, 0.001929, 0.001929, 0.001929, 0.001929, 0.001929, 0.045218,
  0.045218, 0.045218, 0.001929, 0.001929, 0.001929, 0.001929, 0.001929,
  0.001929, 0.001929, 0.001929, 0.001929, 0.045218, 0.045218, 0.045218,
  0.001929, 0.001929, 0.001929, 0.001929, 0.001929, 0.001929, 0.001929,
  0.001929, 0.001929, 0.045218, 0.045218, 0.045218, 0.001929, 0.001929,
  0.001929, 0.001929, 0.001929, 0.001929, 0.001929, 0.001929, 0.001929,
  0.108472, 0.108472, 0.108472, 0.021925, 0.021925, 0.021925, 0.021925,
  0.021925, 0.021925, 0.021925, 0.021925, 0.021925, 0.021925, 0.021925,
  0.021925, 0.108472, 0.108472, 0.108472, 0.021925, 0.021925, 0.021925,
  0.021925, 0.021925, 0.021925, 0.021925, 0.021925, 0.021925, 0.108472,
  0.108472, 0.108472, 0.021925, 0.021925, 0.021925, 0.021925, 0.021925,
  0.021925, 0.021925, 0.021925, 0.021925, 0.108472, 0.108472, 0.108472,
  0.021925, 0.021925, 0.021925, 0.021925, 0.021925, 0.021925, 0.021925,
  0.021925, 0.021925, 0.108472, 0.108472, 0.108472, 0.021925, 0.021925,
  0.021925, 0.021925, 0.021925, 0.021925, 0.021925, 0.021925, 0.021925,
  0.202653, 0.202653, 0.202653, 0.124429, 0.124429, 0.124429, 0.124429,
  0.124429, 0.124429, 0.124429, 0.124429, 0.124429, 0.124429, 0.124429,
  0.124429, 0.202653, 0.202653, 0.202653, 0.124429, 0.124429, 0.124429,
  0.124429, 0.124429, 0.124429, 0.124429, 0.124429, 0.124429, 0.202653,
  0.202653, 0.202653, 0.124429, 0.124429, 0.124429, 0.124429, 0.124429,
  0.124429, 0.124429, 0.124429, 0.124429, 0.202653, 0.202653, 0.202653,
  0.124429, 0.124429, 0.124429, 0.124429, 0.124429, 0.124429, 0.124429,
  0.124429, 0.124429, 0.202653, 0.202653, 0.202653, 0.124429, 0.124429,
  0.124429, 0.124429, 0.124429, 0.124429, 0.124429, 0.124429, 0.124429,
  0.294858, 0.294858, 0.294858, 0.352624, 0.352624, 0.352624, 0.352624,
  0.352624, 0.352624, 0.352624, 0.352624, 0.352624, 0.352624, 0.352624,
  0.352624, 0.294858, 0.294858, 0.294858, 0.352624, 0.352624, 0.352624,
  0.352624, 0.352624, 0.352624, 0.352624, 0.352624, 0.352624, 0.294858,
  0.294858, 0.294858, 0.352624, 0.352624, 0.352624, 0.352624, 0.352624,
  0.352624, 0.352624, 0.352624, 0.352624, 0.294858, 0.294858, 0.294858,
  0.352624, 0.352624, 0.352624, 0.352624, 0.352624, 0.352624, 0.352624,
  0.352624, 0.352624, 0.294858, 0.294858, 0.294858, 0.352624, 0.352624,
  0.352624, 0.352624, 0.352624, 0.352624, 0.352624, 0.352624, 0.352624,
  0.334118, 0.334118, 0.334118, 0.499009, 0.499009, 0.499009, 0.499009,
  0.499009, 0.499009, 0.499009, 0.499009, 0.499009, 0.499009, 0.499009,
  0.499009, 0.334118, 0.334118, 0.334118, 0.499009, 0.499009, 0.499009,
  0.499009, 0.499009, 0.499009, 0.499009, 0.499009, 0.499009, 0.334118,
  0.334118, 0.334118, 0.499009, 0.499009, 0.499009, 0.499009, 0.499009,
  0.499009, 0.499009, 0.499009, 0.499009, 0.334118, 0.334118, 0.334118,
  0.499009, 0.499009, 0.499009, 0.499009, 0.499009, 0.499009, 0.499009,
  0.499009, 0.499009, 0.334118, 0.334118, 0.334118, 0.499009, 0.499009,
  0.499009, 0.499009, 0.499009, 0.499009, 0.499009, 0.499009, 0.499009,
];
