import AudioImage from '@/asset/resource/default-sound.png';

import showTimeIcon from '@/asset/inline/a-showtime.svg';
import JumpIcon from '@/asset/inline/a-shouzhang.svg';
import MaimengIcon from '@/asset/inline/a-dianzan.svg';
import ZuoyiIcon from '@/asset/inline/a-quantou.svg';
import BiXinIcon from '@/asset/inline/a-bixin.svg';
import XuyuanIcon from '@/asset/inline/a-666.svg';

export const GUIDE_VER_CONST = 'GUIDE_VER';
export const GUIDE_VER = '0.2';

// 动作的映射，文档见 https://xhzy.yuque.com/poumiz/ohq8w1/tewkgm#FSENk
export const handActionMap: Array<{
  id: number;
  title: string;
  name: string;
  key: string;
  icon: string;
  aniTime: number;
}> = [
  {
    id: 1,
    name: '作揖',
    key: 'zuoyi',
    title: '金虎报春',
    aniTime: 1,
    icon: 'https://mgc-template.oss-cn-hangzhou.aliyuncs.com/spine/testcase/handicon/01-zuoyi.svg'
  },
  {
    id: 2,
    name: '卖萌',
    key: 'maimeng',
    title: '虎步高升',
    aniTime: 1,
    icon: 'https://mgc-template.oss-cn-hangzhou.aliyuncs.com/spine/testcase/handicon/02-maimeng.svg'
  },
  {
    id: 3,
    name: '跳跃',
    key: 'jump',
    title: '虎力全开',
    aniTime: 1,
    icon: 'https://mgc-template.oss-cn-hangzhou.aliyuncs.com/spine/testcase/handicon/03-tiaoyue.svg'
  },
  {
    id: 4,
    name: '比心',
    key: 'bixin',
    title: '爱老虎油',
    aniTime: 1,
    icon: 'https://mgc-template.oss-cn-hangzhou.aliyuncs.com/spine/testcase/handicon/04-bixin.svg'
  },
  {
    id: 5,
    name: '许愿',
    key: 'xuyuan',
    title: '虎力全开',
    aniTime: 1,
    icon: 'https://mgc-template.oss-cn-hangzhou.aliyuncs.com/spine/testcase/handicon/05-xuyuan.svg'
  },
  // {
  //   id: 6,
  //   name: '召福',
  //   // key: 'zhaofu',
  //   key: 'zuoyi',
  //   title: '5虎临门',
  //   aniTime: 1,
  //   icon: 'https://mgc-template.oss-cn-hangzhou.aliyuncs.com/spine/testcase/handicon/05-xuyuan.svg'
  // }
];
export const effectMap: { [key: string]: EffectConfig } = {
  // zuoyi
  1: {
    src: 'https://mgc-video-store.oss-cn-hangzhou.aliyuncs.com/visionlab/effects/01-540-s.mp4',
    aniName: 'zuoyi',
    icon: ZuoyiIcon,
  },
  // maimeng
  2: {
    src: 'https://mgc-video-store.oss-cn-hangzhou.aliyuncs.com/visionlab/effects/02-540-s.mp4',
    aniName: 'maimeng',
    icon: MaimengIcon,
  },
  // jump
  3: {
    src: 'https://mgc-video-store.oss-cn-hangzhou.aliyuncs.com/visionlab/effects/03-540-s.mp4',
    aniName: 'jump',
    icon: JumpIcon,
  },
  // bixin
  4: {
    src: 'https://mgc-video-store.oss-cn-hangzhou.aliyuncs.com/visionlab/effects/04-540-s.mp4',
    aniName: 'bixin',
    icon: BiXinIcon,
  },
  // xuyuan
  5: {
    src: 'https://mgc-video-store.oss-cn-hangzhou.aliyuncs.com/visionlab/effects/05-540-s.mp4',
    aniName: 'xuyuan',
    icon: XuyuanIcon,
  },
  6: {
    src: 'https://mgc-video-store.oss-cn-hangzhou.aliyuncs.com/visionlab/effects/showtime-540-s.mp4',
    aniName: 'showtime',
    icon: showTimeIcon,
  },
};

// demo1,新年舞
export const assetConfig = {
  key: 'dance1',
  image: AudioImage,
  name: '舞力全开',
  audioDur: 10, // 取音频时长
  steps: [
    '6', // showtime
    '1',
    '2',
    '3',
    '1',
    '4',
    '6', // showtime
  ].map((item, index) => {
    const {
      aniName,
      icon,
    } = effectMap[item];
    return {
      id: `${index}`,
      key: item,
      aniName,
      icon,
    };
  })
};

export type DanceKey = string;

export const dances: { [key: string]: {
  key: string;
  image: string;
  name: string;
  audio: string;
  audioDur: number; // 取音频时长
  steps: {
    id: string;
    key: string;
    aniName: string;
    icon: string;
  }[],
} } = {
  'dance1': {
    ...assetConfig,
    audio: 'https://mgc-template.oss-cn-hangzhou.aliyuncs.com/spine/release/bgm/bgm1.m4a?acors=',
    image: 'https://mgc-template.oss-cn-hangzhou.aliyuncs.com/spine/release/bgm/bgm-1.png',
    key: 'dance1',
    audioDur: 19,
    name: '舞力全开',
  },
  dance2: {
    ...assetConfig,
    audio: 'https://mgc-template.oss-cn-hangzhou.aliyuncs.com/spine/release/bgm/bgm2.m4a?acors=',
    image: 'https://mgc-template.oss-cn-hangzhou.aliyuncs.com/spine/release/bgm/bgm-2.png',
    audioDur: 24,
    key: 'dance2',
    name: '宝宝拜年',
  },
  dance3: {
    ...assetConfig,
    audio: 'https://mgc-template.oss-cn-hangzhou.aliyuncs.com/spine/release/bgm/bgm3.m4a?acors=',
    image: 'https://mgc-template.oss-cn-hangzhou.aliyuncs.com/spine/release/bgm/bgm-3.png',
    audioDur: 20,
    key: 'dance3',
    name: '感恩有你',
  },
};

export const dancesArr = Object.keys(dances).map(item => dances[item]);

export const backgroundObjects: { [key: string]: {
  icon: string;
  image: string;
  name: string;
  key: string;
}} = {
  bg1: {
    icon: 'https://mgc-template.oss-cn-hangzhou.aliyuncs.com/spine/assets/background/thumb-bg1.jpg',
    image: 'https://mgc-template.oss-cn-hangzhou.aliyuncs.com/spine/assets/background/bg1.jpg',
    name: '家',
    key: 'bg1',
  },
  bg2: {
    icon: 'https://mgc-template.oss-cn-hangzhou.aliyuncs.com/spine/assets/background/thumb-bg2.jpg',
    image: 'https://mgc-template.oss-cn-hangzhou.aliyuncs.com/spine/assets/background/bg2.jpg',
    name: '城墙',
    key: 'bg2',
  },
  bg3: {
    icon: 'https://mgc-template.oss-cn-hangzhou.aliyuncs.com/spine/assets/background/thumb-bg3.jpg',
    image: 'https://mgc-template.oss-cn-hangzhou.aliyuncs.com/spine/assets/background/bg3.jpg',
    name: '春色',
    key: 'bg3',
  },
};
export const backgrounds = Object.keys(backgroundObjects).map(item => backgroundObjects[item]);
interface EffectConfig {
  src: string;
  aniName: string;
  icon: string;
  duration?: number; // 暂时没什么用
}
