import { useRef, useEffect, useState } from 'react';
import { Application } from '../routes/JumpRope/app';
import { drawHand } from '../utils/draw-hand';
import { useQuery } from '../utils/hooks/use-query';
import { Effect } from '../routes/JumpRope/effect';
import { HandInfo } from './types';
import styles from './index.module.less';
import { useLocation } from 'react-router';

let rafId: number;

let isDebug = false;

// 是否显示视频小窗的开关
let modelInited = false;

const JumpRope = () => {
  const { search } = useLocation()
  const params = useQuery(search);
  // 临时设置是否识别指定手势
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  // TODO: 从query里面读取
  const effectRef = useRef<Effect>();


  useEffect(() => {
    isDebug = params.has('__debug');
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const a = Application.getInstance({
      canvas,
    });

    const effect = new Effect(canvas.width, canvas.height);
    effectRef.current = effect;

    a.on('model-inited', () => {
      modelInited = true;
    });

    a.on('error', (e) => {
      console.log('错误处理', e);
    });

    let stats: any;
    if (isDebug) {
      stats = Stats();
      containerRef.current!.appendChild(stats.domElement);
    }

    let camera: HTMLVideoElement;

    let infoGlobal: any
    
    const render = () => {
      a.clearCanvas();
      a.renderCamera();
      if (modelInited) {
        a.getHandInfo().then((info: HandInfo) => {
          if (!camera) return;
          if(info) infoGlobal = info
          const landmarks = infoGlobal.keypoints;
          drawHand(canvas, landmarks, {
            layout: camera.layout,
            lineWidth: 2,
          });
        });
      }
      if (stats) {
        stats.update();
      }
      rafId = requestAnimationFrame(render);
    };

    const init = async () => {
      a.on('video-play', () => {
        a.initModel();
      });
      await a.startCamera();
      camera = a.getCamera()!;
      // 启动摄像头后启动spine
      render();
    };

    init();

    return () => {
      a.destroy();
      rafId && cancelAnimationFrame(rafId);
    };
  }, []);

  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.canvasWrapper}>
        <canvas id="output-canvas" ref={canvasRef} />
      </div>
    </div>
  );
};

export default JumpRope;
