import { Canvas } from 'glsl-canvas-js';
import { eventbus } from '@/utils/event-bus';
import { frag } from '@/routes/JumpRope/effect/frag';
import { effectMap } from '@/routes/JumpRope/constant';

const programOptions = {
  fragmentString: frag,
  alpha: true,
  antialias: true,
  mode: 'flat',
  preserveDrawingBuffer: true,
};

export class Effect {
  private program: any;

  private videoContainer: HTMLDivElement;

  // undefined 责任空闲状态
  currentEffect: HTMLVideoElement | undefined = undefined;

  private videoMap: { [key: string]: HTMLVideoElement } = {};

  private canvas: HTMLCanvasElement;

  private prepared = false;

  constructor(width: number, height: number) {
    const canvas = document.createElement('canvas');
    this.canvas = canvas;
    // 按照9:16转换canvas的高度，还原原始特效的比例
    const cur = width / height;
    const ori = 9 / 16;
    if (cur > ori) {
      width = height * ori;
    } else {
      height = width / ori;
    }

    // glsl-canvas会设置canvas的宽高，会使用实际渲染的大小，所以只要设置它的css宽高
    canvas.setAttribute(
      'style',
      `position: absolute; top: 0; left: 0; width: ${width}px; height: ${height}px; visibility: hidden;`
    );
    document.body.appendChild(canvas);

    const container = document.createElement('div');
    container.style.display = 'none';
    this.videoContainer = container;
    document.body.appendChild(container);

    const program = new Canvas(canvas, programOptions);
    program.setUniform(
      'uResolution',
      width * (window.devicePixelRatio ?? 1),
      height * (window.devicePixelRatio ?? 1)
    );

    this.preload(program);
    this.program = program;
  }

  preload(program: any) {
    const promises: Array<Promise<void>> = [];
    // 预加载所有的特效video
    Object.keys(effectMap).forEach((id) => {
      const video = document.createElement('video');
      video.setAttribute(
        'style',
        'position: absolute; top: 0; left: 0; z-index: 0; object-fit: cover; visibility: hidden;'
      );
      video.src = `${effectMap[id].src}`;
      video.playsInline = true;
      video.preload = 'auto';
      video.crossOrigin = 'anonymous';
      // 注意把id传给program做参数
      video.id = `effect_video_${id}`;
      this.videoMap[id] = video;
      video.load();

      promises.push(
        new Promise<void>((r) => {
          video.onloadeddata = () => {
            r();
          };
        })
      );

      this.videoContainer.appendChild(video);
    });

    program.setUniforms({
      uSampler1: '#effect_video_1',
      uSampler2: '#effect_video_2',
      uSampler3: '#effect_video_3',
      uSampler4: '#effect_video_4',
      uSampler5: '#effect_video_5',
      uSampler6: '#effect_video_6',
    });
  }

  prepare() {
    if (this.prepared) return;
    Object.values(this.videoMap).forEach((v) => {
      v.play().then( () => {
        v.currentTime = 0;
        v.pause();
      });
    });
    this.prepared = true;
  }

  play() {
    if (!this.currentEffect) throw Error('effect not settled');
    this.currentEffect.currentTime = 0;
    this.currentEffect.play().then(() => {
      this.program.play();
    }).catch((e) => {
      console.log('H5: 特效播放失败', e);
    });
  }

  pause() {
    this.program.pause();
  }

  set(id: number, play = true) {
    if (this.currentEffect) {
      return;
    }
    const video = this.videoMap[id];

    this.currentEffect = video;
    video.addEventListener('ended', () => {
      this.currentEffect = undefined;
      eventbus.emit('effect-changed', undefined);
    });
    this.program.setUniform('cur', Number(id));
    play && this.play();
    eventbus.emit('effect-changed', id);
  }

  getCanvas() {
    return this.canvas;
  }
}
