
import { Keypoint } from '../routes/JumpRope/app';
import { calAngle } from '../utils/math';
import { EWorkStatus, EVoice, fps } from '../constants'

interface ISize {
    width: number;
    height: number;
}

interface IStartOptions {
    pose_pos: Keypoint[],
    size: ISize;
}

export interface IPlank {
    start: (options: IStartOptions) => void;
    getStatus: () => string;
    getFoul: () => string;
}

export class Plank {
    public score: number;
    // 骨骼点
    public direction_point: Record<string, number[]>;
    public thresh_ready_angle: number;
    public thresh_A: number;
    public thresh_B: number;
    public thresh_C: number;
    public thresh_D: number;
    public thresh_F: number;

    public end_frame_boundary: number;
    // 准备帧数
    public prepare_frame: number;
    // 违规帧数
    public foul_frame: number;
    // 未检测到骨骼点或站起
    public end_frame: number;
    // 违规次数
    public foul_time: number;

    public status: string;
    public foul: string;

    constructor() {
        this.score = 0.65;
        this.direction_point = {
            'left': [11, 13, 15, 23, 25, 27],
            'right': [12, 14, 16, 24, 26, 28],
        }
        this.thresh_ready_angle = 45;
        this.thresh_A = 30;
        this.thresh_B = 30;
        this.thresh_C = 30;
        this.thresh_D = 30;
        this.thresh_F = 5;

        this.end_frame_boundary = 2;

        this.prepare_frame = 0;
        this.foul_frame = 0;
        this.end_frame = 0;
        this.foul_time = 0;

        this.status = EWorkStatus.准备中
        this.foul = ''
    }

    // 姿势是否满足检测条件
    checkVisibility(pose_pos: Keypoint[], direction: string) {

        if (!pose_pos || !Array.isArray(pose_pos)) {
            return false
        }
        return this.direction_point[direction].every((point: number) => {
            return typeof pose_pos[point] === 'object'})
    }

    // 获取检测条件
    calculateRequirements(pose_pos: Keypoint[], direction: string, size: ISize) {
        // 模拟横屏横纵坐标互换
        // 臀部
        const pointA = [pose_pos[this.direction_point[direction][3]].y, pose_pos[this.direction_point[direction][3]].x];
        // 肩膀
        const pointB = [pose_pos[this.direction_point[direction][0]].y, pose_pos[this.direction_point[direction][0]].x];
        // 肘
        const pointC = [pose_pos[this.direction_point[direction][1]].y, pose_pos[this.direction_point[direction][1]].x];
        // 手腕
        const pointD = [pose_pos[this.direction_point[direction][2]].y, pose_pos[this.direction_point[direction][2]].x];
        // 膝盖
        const pointE = [pose_pos[this.direction_point[direction][4]].y, pose_pos[this.direction_point[direction][4]].x];
        // 脚踝
        const pointF = [pose_pos[this.direction_point[direction][5]].y, pose_pos[this.direction_point[direction][5]].x];

        const requirementA = calAngle(pointB, pointC, pointC, pointD);
        const requirementB = calAngle(pointA, pointB, pointA, pointE);
        const requirementC = calAngle(pointE, pointA, pointE, pointF);
        const requirementD = Math.min(
            calAngle(pointD, pointC, pointD, [Math.max(pointD[0] - 100, 0), pointD[1]]),
            calAngle(pointD, pointC, pointD, [Math.max(pointD[0] + 100, 0), pointD[1]])
        )
        const requirementE = Math.min(
            calAngle(pointF, pointB, pointF, [Math.min(size.width, pointF[0] + 100), pointF[1]]),
            calAngle(pointF, pointB, pointF, [Math.min(size.width, pointF[0] - 100), pointF[1]]),
        )
        const requirementF = Math.min(
            calAngle(pointC, pointE, pointC, [Math.min(size.width, pointC[0] + 100), pointC[1]]),
            calAngle(pointC, pointE, pointC, [Math.min(size.width, pointC[0] - 100), pointC[1]]),
        )

        return {
            requirementA,
            requirementB,
            requirementC,
            requirementD,
            requirementE,
            requirementF
        }
    }

    // 更新状态
    updateRunningStatus(conditionA: boolean, conditionB: boolean, conditionC: boolean, conditionD: boolean, conditionF: boolean) {
        // 膝盖弯曲
        if (!conditionC) {
            this.foul_frame++
            console.log('膝盖弯曲')
            this.updateFoul(EVoice.trunk_foul)
            return
        }
        // 手臂弯曲
        if (!conditionA || !conditionD) {
            this.foul_frame++
            console.log('手臂弯曲')
            this.updateFoul(EVoice.arm_foul)
            return
        }
        // 臀部或手肘和膝盖的角度违规
        if (!conditionB || !conditionF) {
            this.foul_frame++
            console.log('臀部或手肘和膝盖的角度违规')
            this.updateFoul(EVoice.trunk_foul)
            return
        }
        this.foul_frame = 0;
        this.end_frame = 0;
        this.updateFoul('')
    }

    // 执行检测
    mainLogic(pose_pos: Keypoint[], direction: string, size: ISize) {
        const { requirementA, requirementB, requirementC, requirementD, requirementE, requirementF } = this.calculateRequirements(pose_pos, direction, size);
        console.log(`requirementA:${requirementA},requirementB:${requirementB}, requirementC:${requirementC}, requirementD:${requirementD}, requirementE:${requirementE}, requirementF:${requirementF}`)
        const conditionA = Math.abs(requirementA - 90) < this.thresh_A
        const conditionB = Math.min(Math.abs(requirementB), Math.abs(requirementB - 180)) < this.thresh_B
        const conditionC = Math.min(Math.abs(requirementC), Math.abs(requirementC - 180)) < this.thresh_C
        const conditionD = Math.abs(requirementD) < this.thresh_D
        const conditionE = requirementE < this.thresh_ready_angle
        const conditionF = requirementF > this.thresh_F
        switch (this.status) {
            case EWorkStatus.初始化:
                break
            case EWorkStatus.准备中:
                // 如果肩膀与踝关节连线与水平面夹角小于某阈值保持一定帧数，则认定为开始状态
                if (conditionA && conditionB && conditionC && conditionD && conditionE && conditionF) {
                    // 保持1s进入开始状态
                    if (this.prepare_frame < fps) {
                        this.prepare_frame++
                        return
                    }
                    this.updateStatus(EWorkStatus.进行中)
                }
                break;
            case EWorkStatus.进行中:
                if (requirementE >= this.thresh_ready_angle && this.end_frame < this.end_frame_boundary) {
                    this.end_frame++
                }
                // 违规5s，进入结束状态
                if (this.foul_frame > fps * 5) {
                    this.foul = EVoice.foul_time_end
                    // this.updateStatus(EWorkStatus.已结束)
                    return
                }
                this.updateRunningStatus(conditionA, conditionB, conditionC, conditionD, conditionF)
                break;
            default:
                return
        }
    }

    start({ pose_pos, size }: IStartOptions) {
        if (this.checkVisibility(pose_pos, 'left') && this.checkVisibility(pose_pos, 'right')) {
            let foul_frame: number = this.foul_frame
            let ready_frame: number = this.prepare_frame
            let end_frame: number = this.end_frame
            this.mainLogic(pose_pos, 'left', size)
            let foul_frame_after: number = this.foul_frame
            let ready_frame_after: number = this.prepare_frame
            let end_frame_after: number = this.end_frame
            if (foul_frame < foul_frame_after || end_frame < end_frame_after) {
                console.log('单边违规')
                return
            }
            this.mainLogic(pose_pos, 'right', size)
            let ready_frame_last: number = this.prepare_frame
            if (ready_frame_last > ready_frame_after && ready_frame_after > ready_frame) {
                this.prepare_frame--
                console.log('两边有效准备')
            }
            return
        }
        if (this.checkVisibility(pose_pos, 'left')) {
            this.mainLogic(pose_pos, 'left', size)
        } else if (this.checkVisibility(pose_pos, 'right')) {
            this.mainLogic(pose_pos, 'right', size)
        } else {
            if (this.status === EWorkStatus.进行中) {
                if (this.end_frame < this.end_frame_boundary) {
                    this.end_frame++
                    return
                }
                this.foul = EVoice.no_person_end
                // this.updateStatus(EWorkStatus.已结束)
            }
        }
    }

    updateStatus(value: string) {
        this.status = value
    }

    updateFoul(value: string) {
        if (this.foul === '' && value) {
            // 违规次数超过3次即结束
            if (this.foul_time >= 3) {
                this.foul = EVoice.foul_num_end
                // this.updateStatus(EWorkStatus.已结束)
                return
            }
            this.foul_time++
        }
        this.foul = value
    }

    getStatus() {
        return this.status
    }

    getFoul() {
        return this.foul
    }
}